import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix'
import {
  json,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from '@remix-run/react'
import { ReactNode } from 'react'
import './tailwind.css'
import { Toaster } from './components/ui/sonner'
import { getToast } from './lib/toast.server'
import { LoaderFunctionArgs } from '@remix-run/node'
import { useToast } from './components/toaster'
import { ThemeProvider } from 'remix-theme'

export async function loader({ request }: LoaderFunctionArgs) {
  const { toast, headers } = await getToast(request)
  return json({ toast }, { headers: headers ?? {} })
}

export function Layout({ children }: { children: ReactNode }) {
  const data = useLoaderData<typeof loader>()

  useToast(data?.toast)

  return (
    // <ThemeProvider attribute='class'>
    // </ThemeProvider>
    (<html lang='en' className='h-full'>
      <head>
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <Meta />
        <Links />
      </head>
      <body className='w-full'>
        {children}
        <ScrollRestoration />
        <Scripts />
        <Toaster richColors />
      </body>
    </html>)
  );
}

function App() {
  return <Outlet />
}

export default withSentry(App)

export function ErrorBoundary() {
  const error = useRouteError()
  console.log(error)
  captureRemixErrorBoundaryError(error)
  return (
    <html>
      <head>
        <title>Oh no!</title>
        <Meta />
        <Links />
      </head>
      <body>
        <p>An error occurred</p>
        <Scripts />
      </body>
    </html>
  )
}